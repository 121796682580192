import { graphql } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import ContactUsSection from "../components/contactUs";
import DonateNow from "../components/donateNow";
import FAQ from "../components/faq";
import ChildCampaignFundsCollection from "../components/childCampaignFundsCollection";
import HeroSection from "../components/heroSection";
import { Layout } from "../components/layout";
import LiveEdit from "../components/liveEdit";
import OurMissionSection from "../components/ourMission";
import TestimonialsSection from "../components/testimonials";
import ChildCampaignDonationInfo from "../components/childCampaignDonationInfo";
import AboutSupportingCampaignerSection from "../components/aboutSupportingCampaigner";
import ChildCampaignTag from "../components/childCampaignTag";

function ChildCampaignDetails(props) {
  const node = props.data.contentfulChildCampaign;
  const parentCampaign = node.parentCampaign;
  const childCampaignSlug = `/campaign/${node.childSlug}`;
  const campaignAbsoluteURL = `${process.env.GATSBY_PUBLIC_URL}${childCampaignSlug}`;
  const campaignBannerImage = `https:${node.parentCampaign.banner[0].image.file.url}`;
  return (
    <>
      <Helmet>
        <meta
          name="og:image"
          property="og:image"
          content={`${campaignBannerImage}?w=430&h=495`}
        />
        <title>{node.parentCampaign.title} | Metta Social</title>
        <meta name="twitter:title" content={node.title} />
        <meta name="og:title" property="og:title" content={node.title} />
        <meta property="og:type" name="og:type" content="website" />
        <meta
          name="description"
          content={node.parentCampaign.seoDescription.seoDescription}
        />
        <meta
          property="og:description"
          name="og:description"
          content={node.parentCampaign.seoDescription.seoDescription}
        />
        <meta
          name="twitter:description"
          content={node.parentCampaign.seoDescription.seoDescription}
        />
        <meta property="og:url" name="og:url" content={campaignAbsoluteURL} />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content="@metta_social" />
        <meta
          property="og:image"
          name="og:image"
          content={`${campaignBannerImage}?w=128&h=128`}
        />
        <meta
          property="og:image"
          name="og:image"
          content={`${campaignBannerImage}?w=192&h=192`}
        />
        <meta
          property="og:image"
          name="og:image"
          content={`${campaignBannerImage}?w=200&h=200`}
        />
        <meta
          property="og:image"
          name="og:image"
          content={`${campaignBannerImage}?w=512&h=512`}
        />
        <meta
          property="og:image"
          name="og:image"
          content={`${campaignBannerImage}?w=800&h=800`}
        />
        <meta
          name="image"
          property="og:image"
          content={`${campaignBannerImage}?w=1200&h=600`}
        />
        <meta
          name="og:image:alt"
          property="og:image:alt"
          content={node.title}
        />
        <meta
          name="twitter:image"
          content={`${campaignBannerImage}?w=128&h=128`}
        />
        {!process.env.GATSBY_ENV && (
          <script
            type="text/javascript"
            src="https://s7.addthis.com/js/300/addthis_widget.js#pubid=ra-609004268a9d389e"
            async
            defer
          ></script>
        )}
      </Helmet>
      <Layout>
        <div className="position-relative row col-12 mx-0 px-0">
          <ChildCampaignTag
            creatorName={node.creatorName}
            ngo={parentCampaign.ngo}
            className="d-none d-md-block"
          />
          <HeroSection
            banner={parentCampaign.banner}
            ngo={parentCampaign.ngo}
          ></HeroSection>
          <ChildCampaignTag
            creatorName={node.creatorName}
            ngo={parentCampaign.ngo}
            className="d-block d-md-none"
          />
          <DonateNow
            campaign={parentCampaign}
            tagLine={parentCampaign.tagLine}
            childCampaign={node}
          />
        </div>

        <ChildCampaignFundsCollection campaign={node} />
        <AboutSupportingCampaignerSection
          creatorName={node.creatorName}
          appeal={node.appeal.appeal}
        />
        <OurMissionSection
          mission={parentCampaign.mission}
          fundUsage={parentCampaign.fundUsage}
        ></OurMissionSection>
        <TestimonialsSection
          testimonials={parentCampaign.testimonials}
        ></TestimonialsSection>

        <ChildCampaignDonationInfo
          className="donationSection"
          childCampaign={node}
          banner={parentCampaign.banner}
          type="section"
        />

        <FAQ faqcontent={parentCampaign.faQs}></FAQ>
        <ContactUsSection></ContactUsSection>
      </Layout>

      {process.env.GATSBY_ENV && <LiveEdit />}
    </>
  );
}
export default ChildCampaignDetails;
export const query = graphql`
  query($id: String!) {
    contentfulChildCampaign(id: { eq: $id }) {
      id
      contentful_id
      goal
      childSlug
      email
      creatorName
      appeal {
        appeal
      }
      parentCampaign {
        id
        slug
        tagLine

        fundUsage {
          buttonText
          title
          contents {
            raw
          }
        }
        ngo {
          ngoName
          rzpKeyId
          accountId
          rzpKeyEncryptedSecret {
            rzpKeyEncryptedSecret
          }
          intRzpKeyId
          intRzpKeyEncryptedSecret {
            intRzpKeyEncryptedSecret
          }
          logo {
            fluid {
              src
              srcSet
              srcSetWebp
              srcWebp
              sizes
            }
            file {
              url
              fileName
            }
            title
          }
        }
        seoDescription {
          seoDescription
        }
        title
        goal
        goalCurrency
        banner {
          buttonLink
          buttontitle
          description
          sequenceno
          title
          image {
            fluid {
              src
              srcSet
              srcSetWebp
              srcWebp
              sizes
            }
            file {
              url
            }
          }
        }
        mission {
          title
          projectReport {
            file {
              url
            }
          }
          description {
            raw
          }
          missionImages {
            fluid {
              src
              srcSet
              srcSetWebp
              srcWebp
              sizes
            }
            file {
              fileName
              url
            }
            title
          }
          missionYoutubeLinks
        }
        testimonials {
          sequenceno
          testimonialBy
          designation
          description
          starrating
          image {
            fluid {
              src
              srcSet
              srcSetWebp
              srcWebp
              sizes
            }
            file {
              url
            }
          }
        }
        faQs {
          answer {
            raw
          }
          question
          sequenceno
        }
      }
    }
  }
`;
